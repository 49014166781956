import VMasker from 'vanilla-masker';

export function formatBrazilianPhoneNumber(phoneNumber: string): string {
    // Remove caracteres não numéricos
    const digits = phoneNumber.replace(/\D/g, '');
  
    // Verifica se o número tem código de área (DDD)
    const hasAreaCode = digits.length === 10 || digits.length === 11;
  
    let formattedNumber = '';
  
    if (hasAreaCode) {
      // Se o número tem código de área
      const areaCode = digits.slice(0, 2);
      const mainNumber = digits.slice(2);
  
      if (mainNumber.length === 9) {
        // Celular (9 dígitos)
        formattedNumber = `(${areaCode}) ${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;
      } else {
        // Fixo (8 dígitos)
        formattedNumber = `(${areaCode}) ${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`;
      }
    } else {
      // Se o número não tem código de área
      if (digits.length === 9) {
        // Celular (9 dígitos)
        formattedNumber = `${digits.slice(0, 5)}-${digits.slice(5)}`;
      } else {
        // Fixo (8 dígitos)
        formattedNumber = `${digits.slice(0, 4)}-${digits.slice(4)}`;
      }
    }
  
    return formattedNumber;
  }


// Função para aplicar máscara de CPF ou CNPJ
export function maskCpfCnpj(value: string) {
  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue.length <= 11) {
    // CPF
    return VMasker.toPattern(cleanedValue, '999.999.999-99');
  } else {
    // CNPJ
    return VMasker.toPattern(cleanedValue, '99.999.999/9999-99');
  }
}


export const formatPrice = (cents: number | string) => {
  const cleanedValue = typeof cents === 'number' ? cents : parseInt(cents.replace(/\D/g, ''), 10);

  return (cleanedValue/ 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

}

export function pluralize(singular: string, plural: string, count: number): string {
  return count === 1 ? singular : plural;
}


export function slugify(str: string): string {
  return str
    .toLowerCase() // Converte a string para minúsculas
    .normalize('NFD') // Normaliza a string para separar caracteres acentuados
    .replace(/[\u0300-\u036f]/g, '') // Remove os acentos
    .replace(/[^a-z0-9\s-]/g, '') // Remove caracteres especiais
    .trim() // Remove espaços no início e no fim
    .replace(/\s+/g, '-') // Substitui espaços por hifens
    .replace(/-+/g, '-'); // Remove hifens duplicados
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) return ''; // handle empty string
  return str.charAt(0).toUpperCase() + str.slice(1);
}